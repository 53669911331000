import React, { useEffect, useState } from 'react';
import * as S from './Contact.style';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Heading from '../Heading';
import emailjs from 'emailjs-com';
import {
  TextInput,
  Form,
  FormOverlay,
  SpinnerWithContainer,
  MessageBox,
} from '../FormControls';
import Button from '../Button';
import {
  VARIANTS_TYPE_2_CONTAINER,
  VARIANTS_TYPE_2_CHILD,
} from '../../constants';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';

function Contact() {
  const [isFormDisabled, setFormDisabled] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState({ state: 'success', show: false });
  const animation = useAnimation();
  const [ref, inView] = useInView({ threshold: 0.1, triggerOnce: true });

  const SERVICE = process.env.GATSBY_EMAILJS_EMAIL_SERVICE;
  const TEMPLATE = process.env.GATSBY_EMAILJS_TEMPLATE;
  const USER_ID = process.env.GATSBY_EMAILJS_USER_ID;

  useEffect(() => {
    if (inView) {
      animation.start('visible');
    } else {
      animation.start('hidden');
    }
  }, [animation, inView]);

  function hideMessage() {
    setMessage({ state: 'success', show: false });
    setFormDisabled(false);
  }

  function sendMessage(values, setSubmitting, resetForm) {
    const { name, email, message } = values;
    const time = new Date();
    setLoading(true);
    setFormDisabled(true);

    emailjs
      .send(
        SERVICE,
        TEMPLATE,
        {
          from_name: name,
          email: email,
          time: time,
          message_html: message,
        },
        USER_ID
      )
      .then(
        () => {
          setLoading(false);
          setMessage({ state: 'success', show: true });
          setSubmitting(false);
          resetForm();
        },
        (err) => {
          setLoading(false);
          setSubmitting(false);
          setMessage({ state: 'fail', show: true });
          console.log(err);
        }
      );
  }
  return (
    <S.Wrapper>
      <S.Container
        ref={ref}
        animate={animation}
        variants={VARIANTS_TYPE_2_CONTAINER}
        initial="hidden"
      >
        <Heading
          id="contact-me"
          subheading="LET'S TALK"
          separator
          variants={VARIANTS_TYPE_2_CHILD}
        >
          CONTACT ME
        </Heading>
        <S.FormContainer>
          <Formik
            initialValues={{
              name: '',
              email: '',
              message: '',
            }}
            validationSchema={Yup.object({
              name: Yup.string()
                .max(15, 'Must be 15 characters or less')
                .required('Please enter your name'),
              email: Yup.string()
                .email('Email address is not valid')
                .required(
                  'Please enter your email address so I that can reply to you'
                ),
              message: Yup.string().required(
                'I told you about me, now tell me about you'
              ),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              sendMessage(values, setSubmitting, resetForm);
            }}
          >
            <Form disabled={isFormDisabled}>
              <TextInput
                label="Name"
                id="name"
                name="name"
                type="text"
                placeholder="Your name"
                disabled={isFormDisabled}
                variants={VARIANTS_TYPE_2_CHILD}
              />
              <TextInput
                label="Email Address"
                id="email"
                name="email"
                type="email"
                placeholder="e.g. example@email.com"
                disabled={isFormDisabled}
                variants={VARIANTS_TYPE_2_CHILD}
              />
              <TextInput
                as="textarea"
                label="Message"
                id="message"
                name="message"
                type="message"
                placeholder="Your message..."
                disabled={isFormDisabled}
                variants={VARIANTS_TYPE_2_CHILD}
              />
              <S.ButtonContainer variants={VARIANTS_TYPE_2_CHILD}>
                <Button type="submit" disabled={isFormDisabled}>
                  SEND
                </Button>
              </S.ButtonContainer>
            </Form>
          </Formik>
          <FormOverlay disabled={isFormDisabled}>
            {isLoading && <SpinnerWithContainer text="Sending message" />}
            <MessageBox
              show={message.show}
              state={message.state}
              handleClick={hideMessage}
            />
          </FormOverlay>
        </S.FormContainer>
      </S.Container>
    </S.Wrapper>
  );
}

export default Contact;
