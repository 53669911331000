import styled from 'styled-components';
import { Form as FormikForm } from 'formik';
import { motion } from 'framer-motion';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: var(--color-background4);
  margin-bottom: 75px;
`;

export const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 764px;
  margin: 0 auto;
  margin-top: 75px;
  padding: 0 30px;
`;

export const FormContainer = styled.div`
  position: relative;
`;

export const ButtonContainer = styled(motion.div)`
  margin-top: 5px;
`;
