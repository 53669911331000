import styled from 'styled-components';
import { Link as GLink } from 'gatsby';

export const Base = styled.button`
  background-color: var(--color-primary);
  color: #fff;
  border: none;
  outline: none;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  padding: 10px 40px;
  border-radius: 2px;
  font-weight: 600;
  font-size: 13px;
  transition: background-color 300ms ease-in-out;
  cursor: pointer;
  width: ${(props) => props.fullWidth && '100%'};
  :hover {
    background-color: var(--color-primary-accent);
    color: #fff;
  }
  @media screen and (min-width: 768px) {
    font-size: 16px;
    padding: 16px 40px;
  }
`;

export const Disabled = styled(Base)`
  background-color: var(--color-gray300);
  cursor: auto;
  :hover {
    background-color: var(--color-gray300);
  }
`;

export const Secondary = styled(Base)`
  /* background-color: var(--color-secondary); */
`;

export const Outline = styled(Base)`
  background: none;
  border: 2px solid #fff;
  color: #fff;
  padding: 5px 34px;
`;

// export const Link = styled();

/* export const PrimaryButton = styled.button`
  background-color: ${(props) =>
    props.disabled ? 'var(--color-gray300)' : 'var(--color-primary)'};
  color: #fff;
  border: none;
  outline: none;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  padding: 10px 40px;
  border-radius: 2px;
  font-weight: 600;
  font-size: 13px;
  transition: background-color 300ms ease-in-out;
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  :hover {
    background-color: ${(props) =>
      props.disabled ? 'var(--color-gray300)' : 'var(--color-primary-accent)'};
  }
  @media screen and (min-width: 768px) {
    font-size: 16px;
    padding: 16px 40px;
  }
`;
 */
