import React, { useEffect, useState } from 'react';
import Button from '../Button';
import BGVideo from '../BGVideo';
import * as S from './Hero.style';
import {
  VARIANTS_CONTAINER_SLIDE,
  VARIANTS_INDIVIDUAL_SLIDE,
} from '../../constants';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import IconGitHub from '../../assets/images/GitHub.png';
import IconMedium from '../../assets/images/Medium.png';
import IconLinkedIn from '../../assets/images/LinkedIn.png';
import IconYoutube from '../../assets/images/Youtube.png';

// const BGVideo = React.lazy(() => import('../BGVideo'));

function Hero() {
  const [pageLoaded, setPageLoaded] = useState(false);
  useEffect(() => {
    if (!pageLoaded) {
      setPageLoaded(true);
    }
  }, []);
  const socialMediaIcons = [
    {
      name: 'GitHub',
      icon: IconGitHub,
      to: 'https://www.github.com/martink-rsa',
    },
    {
      name: 'Medium',
      icon: IconMedium,
      to: 'https://medium.com/@martink_rsa',
    },
    {
      name: 'LinkedIn',
      icon: IconLinkedIn,
      to: 'https://www.linkedin.com/in/martink-rsa',
    },
    {
      name: 'Youtube',
      icon: IconYoutube,
      to: 'https://www.youtube.com/channel/UC6Yp7xFI2SZJQxmvzwh4myA/',
    },
  ];
  return (
    <S.Wrapper>
      <BGVideo pageLoaded={pageLoaded} />
      <S.Container
        variants={VARIANTS_CONTAINER_SLIDE}
        initial={'before'}
        animate={'after'}
      >
        <S.Heading variants={VARIANTS_INDIVIDUAL_SLIDE}>
          MARTIN KRUGER
          <br />
          FULLSTACK WEB DEVELOPER
        </S.Heading>
        <S.Separator variants={VARIANTS_INDIVIDUAL_SLIDE} />
        <S.Subtext variants={VARIANTS_INDIVIDUAL_SLIDE}>
          <h2>
            Fullstack Web Developer based in South Africa. Specialized in React
            and translating designs to pixel-perfect code.
          </h2>
        </S.Subtext>
        <S.ButtonContainer variants={VARIANTS_INDIVIDUAL_SLIDE}>
          <Button as={AnchorLink} to="/#contact-me">
            CONTACT ME
          </Button>
        </S.ButtonContainer>
        <S.Footer variants={VARIANTS_INDIVIDUAL_SLIDE}>
          <S.Text>VISIT MY SOCIAL MEDIA AT...</S.Text>
          <S.SocialContainer>
            <S.SocialList>
              {socialMediaIcons.map((item, index) => (
                <li key={index}>
                  <a href={item.to}>
                    <S.Icon src={item.icon} alt={`${item.name} profile`} />
                  </a>
                </li>
              ))}
            </S.SocialList>
          </S.SocialContainer>
        </S.Footer>
      </S.Container>
    </S.Wrapper>
  );
}

export default Hero;
