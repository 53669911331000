import React, { useEffect } from 'react';
import * as S from './Why.style';
import WorkedWith from '../WorkedWith';
import Heading from '../Heading';
import {
  VARIANTS_TYPE_2_CONTAINER,
  VARIANTS_TYPE_2_CHILD,
} from '../../constants';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';

function Why({ companies }) {
  const animation = useAnimation();
  const [ref, inView] = useInView({ threshold: 0.1, triggerOnce: true });

  useEffect(() => {
    if (inView) {
      animation.start('visible');
    } else {
      animation.start('hidden');
    }
  }, [animation, inView]);

  return (
    <S.Wrapper
      ref={ref}
      animate={animation}
      variants={VARIANTS_TYPE_2_CONTAINER}
      initial="hidden"
    >
      <S.Container>
        <Heading
          subheading="PROFESSIONAL • SKILLED • PASSIONATE"
          separator
          variants={VARIANTS_TYPE_2_CHILD}
        >
          Why Work With Me
        </Heading>
        <S.Text variants={VARIANTS_TYPE_2_CHILD}>
          <p>
            I have over a decade of experience working with high-profile
            clients. I pride myself in my strong work ethic and delivering high
            quality work consistently. I am passionate about my work and always
            go the extra mile. I will keep at a problem until I can find a way
            to solve it. I communicate and conduct myself in a professional
            manner while also being proficient at English.
          </p>
          <p>
            I strive to write robust, clean and maintainable code. I think
            outside of the box and I’m always trying to find the best solution
            to a problem.
          </p>
        </S.Text>
      </S.Container>
      <WorkedWith companies={companies} variants={VARIANTS_TYPE_2_CHILD} />
    </S.Wrapper>
  );
}

export default Why;
