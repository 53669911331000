import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const Container = styled(motion.div)``;

const MainHeading = styled.h2`
  text-align: center;
  font-size: 45px;
  font-family: 'Trump Gothic East', sans-serif;
  text-transform: uppercase;
  margin-bottom: 23px;
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.min.m + 'px'}) {
    font-size: 55px;
  }
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.min.l + 'px'}) {
    font-size: 60px;
  }
`;

const Subheading = styled.h3`
  text-align: center;
  font-size: 16px;
  letter-spacing: 3px;
  margin: 0;
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.min.s + 'px'}) {
    font-size: 18px;
  }
`;

const Separator = styled.div`
  background: var(--color-primary);
  height: 2px;
  width: 150px;
  margin: 40px auto;
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.min.s + 'px'}) {
    height: 4px;
  }
`;

function Heading({ id, subheading, separator, variants, children }) {
  return (
    <Container variants={variants}>
      <MainHeading id={id}>{children}</MainHeading>
      {subheading && <Subheading>{subheading}</Subheading>}
      {separator && <Separator />}
    </Container>
  );
}

export default Heading;
