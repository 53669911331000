import React, { useEffect } from 'react';
import * as S from './LatestVideo.style';
import Heading from '../Heading';
import {
  VARIANTS_TYPE_2_CONTAINER,
  VARIANTS_TYPE_2_CHILD,
} from '../../constants';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';

function LatestVideo({ companies }) {
  const animation = useAnimation();
  const [ref, inView] = useInView({ threshold: 0.1, triggerOnce: true });

  useEffect(() => {
    if (inView) {
      animation.start('visible');
    } else {
      animation.start('hidden');
    }
  }, [animation, inView]);

  return (
    <S.Wrapper
      ref={ref}
      animate={animation}
      variants={VARIANTS_TYPE_2_CONTAINER}
      initial="hidden"
    >
      <S.Container>
        <Heading separator variants={VARIANTS_TYPE_2_CHILD}>
          Latest Video
        </Heading>
        <S.Content variants={VARIANTS_TYPE_2_CHILD}>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/sdCNkEMhGKM"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </S.Content>
      </S.Container>
    </S.Wrapper>
  );
}

export default LatestVideo;
