import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  filter: grayscale(100%);
  opacity: 0.1;

  video {
    display: none;

    @media screen and (min-width: ${(props) =>
        props.theme.breakpoints.min.s + 'px'}) {
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: initial;
    }
  }
`;

export const Image = styled.img`
  min-width: 100vw;
  min-height: 100vh;
  height: 100%;
`;
