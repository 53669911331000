import { motion } from 'framer-motion';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Wrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 30px;
  svg {
    margin: 20px 20px 0 20px;
  }
`;

export const Icon = styled(FontAwesomeIcon)`
  font-size: 40px;
`;
