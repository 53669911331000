import React from 'react';
import * as S from './Skills.style';
import {
  VARIANTS_CONTAINER_SLIDE,
  VARIANTS_INDIVIDUAL_SLIDE,
} from '../../constants';

import {
  faGit,
  faReact,
  faFigma,
  faNodeJs,
  faHtml5,
  faCss3Alt,
  faLess,
  faLinux,
  faAdobe,
  faApple,
  faJoomla,
  faShopify,
  faJs,
  faSass,
  faWindows,
} from '@fortawesome/free-brands-svg-icons';

const icons = [
  { title: 'Git', icon: faGit },
  { title: 'HTML5', icon: faHtml5 },
  { title: 'CSS3', icon: faCss3Alt },
  { title: 'SASS', icon: faSass },
  { title: 'LESS', icon: faLess },
  { title: 'JavaScript', icon: faJs },
  { title: 'React', icon: faReact },
  { title: 'Node.js', icon: faNodeJs },
  { title: 'Figma', icon: faFigma },
  { title: 'Adobe Creative Suite', icon: faAdobe },
  { title: 'Joomla', icon: faJoomla },
  { title: 'Shopify', icon: faShopify },
  { title: 'MacOS', icon: faApple },
  { title: 'Linux', icon: faLinux },
  { title: 'Windows', icon: faWindows },
];

function Skills({ variants }) {
  return (
    <S.Wrapper variants={variants}>
      {icons.map((item, index) => (
        <S.Icon
          key={index}
          icon={item.icon}
          color="#bdbdbd"
          title={item.title}
        />
      ))}
    </S.Wrapper>
  );
}

export default Skills;
