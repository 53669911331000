import React from 'react';
import * as S from './WorkedWith.style';
import Img from 'gatsby-image';

function WorkedWith({ companies, variants }) {
  return (
    <S.Wrapper variants={variants}>
      <S.Text>
        Some of the companies I've previously worked with include:
      </S.Text>
      <S.IconsContainer>
        {companies.map((company, index) => (
          <Img
            key={index}
            fixed={company.image}
            alt={company.name}
            title={company.name}
          />
        ))}
      </S.IconsContainer>
    </S.Wrapper>
  );
}

export default WorkedWith;
