import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import * as S from './BlogSection.style';
import Button from '../Button';
import BlogPostCard from '../BlogPostCard/BlogPostCard';
import Heading from '../Heading';
import { slugify } from '../../utils/utils';
import { Link } from 'gatsby';
import {
  VARIANTS_TYPE_2_CONTAINER,
  VARIANTS_TYPE_2_CHILD,
} from '../../constants';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';

function BlogSection() {
  const data = useStaticQuery(graphql`
    query BlogSectionPosts {
      site {
        siteMetadata {
          title
        }
      }
      allMdx(sort: { order: DESC, fields: [frontmatter___date] }, limit: 1000) {
        edges {
          node {
            id
            frontmatter {
              title
              date
              image {
                childImageSharp {
                  fluid(maxWidth: 680) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            excerpt(pruneLength: 160)
            fields {
              slug
            }
          }
        }
      }
    }
  `);

  const animation = useAnimation();
  const [ref, inView] = useInView({ threshold: 0.1, triggerOnce: true });

  useEffect(() => {
    if (inView) {
      animation.start('visible');
    } else {
      animation.start('hidden');
    }
  }, [animation, inView]);

  const blogPosts = data.allMdx.edges.filter(({ node }, index) => {
    return node.fields.slug.split('/').filter(Boolean).length >= 2;
  });

  return (
    <S.Wrapper>
      <S.Container
        ref={ref}
        animate={animation}
        variants={VARIANTS_TYPE_2_CONTAINER}
        initial="hidden"
      >
        <Heading subheading="" separator variants={VARIANTS_TYPE_2_CHILD}>
          FEATURED BLOG POSTS
        </Heading>
        <S.Cards variants={VARIANTS_TYPE_2_CHILD}>
          {blogPosts.slice(0, 6).map(({ node }, index) => {
            const {
              excerpt,
              fields: { slug },
            } = node;
            const paths = slug.split('/').filter(Boolean);
            const { title, date } = node.frontmatter;
            const image = node.frontmatter.image.childImageSharp.fluid;
            return (
              <BlogPostCard
                key={index}
                slug={paths[1]}
                title={title}
                date={date}
                category={paths[0]}
                excerpt={excerpt}
                image={image}
              />
            );
          })}
        </S.Cards>
        <S.ButtonContainer variants={VARIANTS_TYPE_2_CHILD}>
          <Button as={Link} to="/blog">
            View More
          </Button>
        </S.ButtonContainer>
      </S.Container>
    </S.Wrapper>
  );
}

export default BlogSection;
