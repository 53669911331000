import React, { useEffect } from 'react';
import Skills from '../Skills';
import * as S from './About.style';
import Heading from '../Heading';
import {
  VARIANTS_TYPE_2_CONTAINER,
  VARIANTS_TYPE_2_CHILD,
} from '../../constants';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';

function About() {
  const animation = useAnimation();
  const [ref, inView] = useInView({ threshold: 0.1, triggerOnce: true });

  useEffect(() => {
    if (inView) {
      animation.start('visible');
    } else {
      animation.start('hidden');
    }
  }, [animation, inView]);

  return (
    <S.Wrapper>
      <S.Container
        ref={ref}
        animate={animation}
        variants={VARIANTS_TYPE_2_CONTAINER}
        initial="hidden"
      >
        <Heading
          subheading="FULLSTACK WEB DEVELOPER"
          separator
          variants={VARIANTS_TYPE_2_CHILD}
        >
          About Me
        </Heading>
        <S.Text variants={VARIANTS_TYPE_2_CHILD}>
          <S.Column>
            <p>
              My interest in computers sparked at the age of 6 when my father
              brought home a Commodore 64. Since then I’ve developed a deep
              passion for computers and programming.
            </p>
            <p>
              I began my career as a software developer, with a focus in
              property rental and legal software. I now focus on fullstack web
              development, specializing in React. I am also a programming and
              web development mentor.
            </p>
          </S.Column>
          <S.Column>
            <p>
              Many years of experience in web design and using Adobe products
              has taught me to implement pixel-perfect translations of designs
              into code with great efficiency and cleanliness.
            </p>
            <p>
              I also have a diploma in music production and enjoy graphic
              design. I really love the ocean, snorkeling and scuba diving; and
              it's become my daily routine to go for a swim in the ocean with my
              dogs.
            </p>
          </S.Column>
        </S.Text>
        <Skills variants={VARIANTS_TYPE_2_CHILD} />
      </S.Container>
    </S.Wrapper>
  );
}

export default About;
