import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  background: #111517;
`;

export const Container = styled(motion.div)`
  margin: 0 130px;
  color: #fff;
  text-align: center;
  margin: 0 auto;
  padding: 0 20px;
  z-index: 9;
  h1 {
    color: #fff;
  }
  h2 {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.4;
  }
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.min.s + 'px'}) {
    margin: 0 130px;
    text-align: left;
    color: #fff;
    h2 {
      font-size: 18px;
      font-weight: normal;
      line-height: 1.4;
    }
  }
`;

export const Heading = styled(motion.h1)``;

export const Separator = styled(motion.div)`
  height: 2px;
  width: 50px;
  margin: 30px auto;
  background-color: var(--color-primary);
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.min.s + 'px'}) {
    height: 4px;
    margin: 35px 0;
  }
`;

export const Subtext = styled(motion.div)`
  max-width: 360px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.min.s + 'px'}) {
    max-width: 500px;
    text-align: left;
    margin: 35px 0;
  }
`;

export const ButtonContainer = styled(motion.div)``;

export const Footer = styled(motion.footer)`
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: calc(100% - 60px);
  height: 80px;
  border-top: 1px solid rgba(222, 222, 223, 0.2);
  margin: 0 30px;
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.min.s + 'px'}) {
    justify-content: space-between;
    margin: 0 130px;
    width: calc(100% - 260px);
  }
`;

export const SocialContainer = styled(motion.div)`
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.min.s + 'px'}) {
    width: 100%;
  }
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.min.xl + 'px'}) {
    width: auto;
  }
`;

export const SocialList = styled.ul`
  display: flex;
  width: 100%;
  justify-content: space-between;
  list-style-type: none;
  margin: 0;
  padding: 0;
  li {
    margin: 0;
    margin-left: 10px;

    @media screen and (min-width: ${(props) =>
        props.theme.breakpoints.min.xs + 'px'}) {
      margin-left: 20px;
    }
  }
  li:first-of-type {
    margin-left: 0;
  }
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.min.xl + 'px'}) {
    width: auto;
  }
`;

export const Text = styled.div`
  font-size: 12px;
  color: #fff;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.max.xl + 'px'}) {
    display: none;
  }
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.min.xl + 'px'}) {
    font-size: 15px;
  }
`;

export const Icon = styled.img`
  margin: 0;
  width: auto;
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.min.xs + 'px'}) {
    height: 17px;
  }
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.min.s + 'px'}) {
    height: 25px;
  }
`;
