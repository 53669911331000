import React from 'react';
import * as S from './BGVideo.style';
import { useMediaQuery } from 'react-responsive';

function BGVideo({ pageLoaded }) {
  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  });

  if (!pageLoaded) {
    return null;
  }
  return (
    <S.Wrapper>
      {isMobile ? (
        <S.Image src="./bg2.jpg" />
      ) : (
        <video loop muted autoPlay playsInline>
          <source src={'./ocean.mp4'} type="video/mp4" />
        </video>
      )}
    </S.Wrapper>
  );
}

export default BGVideo;
