import React from 'react';
import './Spinners.css';

function Spinner1() {
  return (
    <div class="spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
  );
}

export { Spinner1 };
