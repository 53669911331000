import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: var(--color-background2);
`;

export const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 764px;
  margin: 0 auto;
  margin-top: 75px;
`;

export const Content = styled(motion.div)`
  font-size: 14px;
  text-align: center;
  padding: 0 30px;
  iframe {
    width: 100%;
    max-width: 560px;
  }
`;
