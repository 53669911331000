import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  min-height: 600px;
  width: 100%;
  background: var(--color-background4);
  margin-bottom: 75px;
`;

export const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 764px;
  margin: 0 auto;
  margin-top: 75px;
`;

export const Text = styled(motion.div)`
  font-size: 14px;
  text-align: center;
  padding: 0 30px;
`;
