import React from 'react';
import PropTypes from 'prop-types';
import * as S from './Layout.style';
import './Layout.scss';
import { ThemeProvider } from 'styled-components';
import { THEME } from '../../constants';
import NavMain from '../NavMain/NavMain';
import Footer from '../Footer/Footer';

const headerLinks = [
  { text: 'Home', to: '/' },
  // { text: 'About', to: '#about' },
  // { text: 'Portfolio', to: '#portfolio' },
  { text: 'Blog', to: '/blog' },
  // { text: 'Contact', to: '#contact' },
];

function Layout({ children }) {
  return (
    <ThemeProvider theme={THEME}>
      <NavMain links={headerLinks} page="home" />
      {/* <Header siteTitle={data.site.siteMetadata.title} links={headerLinks} /> */}
      <S.Wrapper>
        <main>{children}</main>
        <Footer />
      </S.Wrapper>
    </ThemeProvider>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
