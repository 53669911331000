import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: var(--color-background2);
`;

export const Container = styled(motion.section)`
  width: 100%;
  margin: 75px 32px;

  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.min.s + 'px'}) {
    margin: 75px 30px;
  }
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.min.m + 'px'}) {
    margin: 75px 150px;
  }
`;

export const Heading = styled.h2``;

export const Cards = styled(motion.ul)`
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  margin: 0 auto;
  padding: 0;
  max-width: 1160px;
  grid-gap: 25px;
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.min.s + 'px'}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.min.l + 'px'}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const ButtonContainer = styled(motion.div)`
  margin-top: 40px;
  display: flex;
  justify-content: center;
`;
