import React from 'react';
import PropTypes from 'prop-types';
import * as S from './Button.style';

function Button({
  to,
  as,
  secondary,
  outline,
  disabled,
  fullWidth,
  onClick,
  children,
}) {
  if (disabled) {
    return (
      <S.Disabled
        as={as}
        disabled={disabled}
        fullWidth={fullWidth}
        onClick={onClick}
      >
        {children}
      </S.Disabled>
    );
  }
  if (secondary) {
    return (
      <S.Secondary as={as} fullWidth={fullWidth} onClick={onClick}>
        {children}
      </S.Secondary>
    );
  }

  if (outline) {
    return (
      <S.Outline as={as} fullWidth={fullWidth} onClick={onClick}>
        {children}
      </S.Outline>
    );
  }
  return (
    <S.Base as={as} to={to} fullWidth={fullWidth} onClick={onClick}>
      {children}
    </S.Base>
  );
}

Button.defaultProps = {};

Button.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Button;
