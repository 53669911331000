import React from 'react';
import styled from 'styled-components';
import { Form as FormikForm, useField } from 'formik';
import { Spinner1 } from '../Spinners';
import { BiCheckCircle, BiXCircle } from 'react-icons/bi';
import Button from '../Button';
import { motion } from 'framer-motion';

const Form = styled(FormikForm)`
  position: relative;
  display: flex;
  flex-direction: column;
  opacity: ${(props) => (props.disabled ? 0.1 : 1)};
  transition: opacity 300ms ease-in-out;
  textarea {
    resize: vertical;
    min-height: 180px;
  }
`;

const FormOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  opacity: ${(props) => (props.disabled ? 1 : 0)};
  pointer-events: ${(props) => (props.disabled ? 'auto' : 'none')};
  transition: opacity 300ms ease-in-out;
`;

const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`;

const Label = styled.label`
  margin-bottom: 5px;
`;

const Input = styled.input`
  padding: 10px 10px;
  background: var(--color-background);
  border: 1px solid var(--color-gray300);
  color: var(--color-text);
  :focus {
    outline: 2px solid var(--color-primary);
    outline-offset: -2px;
  }
`;

const ErrorMessage = styled.div`
  color: var(--color-primary);
  min-height: 22px;
  padding-top: 6px;
  font-size: 12px;
  line-height: 1;
`;

function TextInput({ label, variants, ...props }) {
  const [field, meta] = useField(props);
  return (
    <Container variants={variants}>
      <Label htmlFor={props.id || props.name}>{label}</Label>
      <Input {...field} {...props} />
      <ErrorMessage>
        {meta.touched && meta.error ? meta.error : null}
      </ErrorMessage>
    </Container>
  );
}

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--color-text);
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 20px 40px 20px 40px;
  p {
    margin: 0;
    margin-bottom: 20px;
  }
`;

const BoxContainer = styled.div`
  opacity: ${(props) => (props.show ? 1 : 0)};
  border-radius: 4px;
  background: var(--color-primary);
  color: #fff;
  min-width: 300px;
  padding: 15px 15px;
  transition: opacity 300ms ease-in-out;
  svg {
    font-size: 40px;
    margin-bottom: 5px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  padding: 0 10px;
  p {
    margin-bottom: 10px;
  }
  a {
    color: #fff;
  }
`;

function MessageBox({ state, show, handleClick }) {
  return (
    <BoxContainer show={show}>
      <Content>
        {state === 'fail' ? (
          <>
            <BiXCircle />
            <p>Failed to send your message.</p>
            <p>
              You can email me directly at{' '}
              <a href="mailto:martin@martinkruger.com">
                martin@martinkruger.com
              </a>
            </p>
          </>
        ) : (
          <>
            <BiCheckCircle />
            <div>Your message was successfully sent!</div>
          </>
        )}
      </Content>
      <div>
        <Button outline fullWidth onClick={handleClick}>
          {state === 'fail' ? 'Thanks' : 'Ok'}
        </Button>
      </div>
    </BoxContainer>
  );
}

function SpinnerWithContainer({ text }) {
  return (
    <SpinnerContainer>
      <p>{text}</p>
      <Spinner1 />
    </SpinnerContainer>
  );
}

export { TextInput, Form, FormOverlay, SpinnerWithContainer, MessageBox };
