import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 600px;
  width: 100%;
  background: var(--color-background2);
`;

export const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1216px;
  margin: 0 auto;
  margin-top: 75px;
  margin-bottom: 75px;
`;

export const HeadingContainer = styled.div``;

export const Text = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  font-size: 14px;
`;

export const Column = styled.div`
  width: 100%;
  padding: 0 30px;
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.min.s + 'px'}) {
    width: 50%;
    padding: 0 30px;
    padding: 0 20px 0 30px;
    :last-of-type {
      padding: 0 30px 0 20px;
    }
  }
`;
