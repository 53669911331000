import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO/SEO';
import Hero from '../components/Hero';
import About from '../components/About';
// import Feature from '../components/Feature';
import BlogSection from '../components/BlogSection';
import Why from '../components/Why/Why';
import Contact from '../components/Contact/Contact';
import LatestVideo from '../components/LatestVideo';

export const squareImage = graphql`
  fragment squareImage on File {
    childImageSharp {
      fluid(maxWidth: 200) {
        ...GatsbyImageSharpFluid
      }
      fixed {
        ...GatsbyImageSharpFixed
      }
    }
  }
`;
function IndexPage() {
  const data = useStaticQuery(graphql`
    query MainIndexQuery {
      site {
        siteMetadata {
          title
        }
      }
      logo_rgt: file(relativePath: { eq: "work/rgts.png" }) {
        ...squareImage
      }
      logo_mkft: file(relativePath: { eq: "work/mkft.png" }) {
        ...squareImage
      }
      logo_monster: file(relativePath: { eq: "work/monster.png" }) {
        ...squareImage
      }
      logo_sunintl: file(relativePath: { eq: "work/sunintl.png" }) {
        ...squareImage
      }
      logo_yipin: file(relativePath: { eq: "work/yipin.png" }) {
        ...squareImage
      }
      logo_weylandts: file(relativePath: { eq: "work/weylandts.png" }) {
        ...squareImage
      }
    }
  `);

  const MKFT = data.logo_mkft.childImageSharp.fixed;
  const Monster = data.logo_monster.childImageSharp.fixed;
  const RGT = data.logo_rgt.childImageSharp.fixed;
  const SunIntl = data.logo_sunintl.childImageSharp.fixed;
  const YiPin = data.logo_yipin.childImageSharp.fixed;
  const Weylandts = data.logo_weylandts.childImageSharp.fixed;

  const companies = [
    { name: 'MKFT Properties', image: MKFT },
    { name: 'Monster Energy', image: Monster },
    { name: 'RGT Smart', image: RGT },
    { name: 'Sun International', image: SunIntl },
    { name: 'Weylandts', image: Weylandts },
    { name: 'Yi Pin Restaurant', image: YiPin },
  ];

  return (
    <Layout>
      <SEO title="Home" />
      <Hero />
      <About />
      <Why companies={companies} />
      <LatestVideo />
      <BlogSection />
      <Contact />
    </Layout>
  );
}

export default IndexPage;
